import React from 'react';
import { useNavigate } from 'react-router-dom';

import useLocalStorage from './LocalStorage';
import { useAppSelector } from './ReduxHook';

const useSaveQueryParams = () => {
  const {
    deleteKeyFromLocalStorage,
    getValueFromLocalStorage,
    saveValueToLocalStorage
  } = useLocalStorage();

  const saveLangToLocalStorage = (value) => {
    const latestQueryStrings = getValueFromLocalStorage('queryStrings') || {};

    const newQueryStrings = {
      ...latestQueryStrings,
      lang: value
    };

    saveValueToLocalStorage('queryStrings', newQueryStrings);
  };

  const deleteStoreFromLocalStorage = (key) => deleteKeyFromLocalStorage(key);

  const getLangFromQueryStrings = () => {
    const lang = new URL(document.location).searchParams.get('lang');
    return lang;
  };

  const getAllQueryStrings = () => {
    const urlParams = new URL(document.location).searchParams;
    const params = {};

    for (const [key, value] of urlParams.entries()) {
      params[key] = value;
    }

    return params;
  };

  const getLangFromLocalStorage = () => {
    const queryParamsFromLocalStorage =
      getValueFromLocalStorage('queryStrings');
    return queryParamsFromLocalStorage?.lang;
  };

  return {
    getAllQueryStrings,
    queryParamsFromLocalStorage: getValueFromLocalStorage('queryStrings'),
    saveLangToLocalStorage,
    getLangFromLocalStorage,
    getLangFromQueryStrings,
    deleteStoreFromLocalStorage
  };
};

export default useSaveQueryParams;
