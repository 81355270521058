import React from 'react';

export const useLocalStorage = () => {
  const saveValueToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  const getValueFromLocalStorage = (key) => {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      return undefined;
    }
  };
  const clearLocalStorage = () => {
    localStorage.clear();
  };
  const deleteKeyFromLocalStorage = (key) => {
    localStorage.removeItem(key);
  };
  const getAllQueryStrings = () => {
    const urlParams = new URL(document.location).searchParams;
    const params = {};

    for (const [key, value] of urlParams.entries()) {
      params[key] = value;
    }

    return params;
  };

  return {
    clearLocalStorage,
    deleteKeyFromLocalStorage,
    getValueFromLocalStorage,
    saveValueToLocalStorage,
    getAllQueryStrings
  };
};

export default useLocalStorage;
